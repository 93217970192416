import React from "react"
import Layout from "../../layouts/default"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "styled-components"
import SEO from "../../components/seo"

const Content = styled.div`
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0 auto;
    color: ${props => props.theme.textDark};
  }
  h3 {
    color: ${props => props.theme.textDark};
    font-size: 24px;
    margin: 40px auto 20px;
  }
  h4 {
    margin: 20px auto 30px;
    color: ${props => props.theme.primaryColor2};
  }
  p {
    line-height: 1.5;
    font-size: 18px;
  }

  blockquote {
    border-left: 5px solid ${props => props.theme.primaryColor};
    padding: 10px;
    padding-left: 1rem !important;
    border-radius: 5px;
    margin: 50px auto;
    background: ${props => props.theme.primaryColor3};
    p {
      margin: 10px auto;
    }
  }

  a {
    color: ${props => props.theme.primaryColor};
  }

  ul {
    list-style: none;
  }

  ul li {
    padding-left: 1em;
    text-indent: -0.7em;
    margin-bottom: 10px;
    line-height: 1;
    p {
      display: inline;
    }
  }

  ul li::before {
    display: inline;
    content: "🥂 ";
    color: ${props => props.theme.primaryColor2};
    font-size: 25px;
    margin-left: -0.7em;
  }

  hr {
    width: 100%;
    border: 0;
    height: 1px;
    margin: 20px auto;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.25),
      rgba(255, 255, 255, 0)
    );
  }

  @media only screen and (max-width: 786px) {
    blockquote {
      margin: 25px auto;
    }
    p {
      font-size: 16px;
    }
    h4 {
      font-size: 15px;
      margin: 10px auto;
    }
    h3 {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 370px) {
    blockquote {
      margin: 15px auto;
    }
    p {
      font-size: 14px;
    }
    h4 {
      font-size: 18px;
    }
  }
`

const Container = styled.div`
  max-width: 800px;
  margin: 50px auto;
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  @media only screen and (max-width: 786px) {
    width: 85%;
  }
`

const PostTitle = styled.h1`
  color: ${props => props.theme.textDark};
  font-weight: bold;
  font-size: 26px;
  @media only screen and (max-width: 786px) {
    font-size: 22px;
  }
`
const PostLink = styled.a`
  display: block;
  color: ${props => props.theme.primaryColor};
  padding: 20px;
  border: 4px solid;
  border-top: 4px solid #e3e3e3;
  border-radius: 40px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.5s ease;
  margin: 40px 10px;
  text-decoration: none;
  font-weight: bold;
  :hover {
    border: 4px solid #e3e3e3;
    border-top: 4px solid ${props => props.theme.primaryColor};
    background: ${props => props.theme.primaryColor};
    color: #fff;
  }
  @media only screen and (max-width: 480px) {
    padding: 10px;
    border-radius: 10px;
    margin: 40px 0;
  }
`

const Image = styled.img`
  margin: 0px auto 40px;
  display: block;
  max-width: 100%;
  width: 100%;
`

export default ({ data }) => (
  <Layout>
    <SEO
      title={data.contentfulBlogPost.title}
      description={data.contentfulBlogPost.preview}
    />
    <Container>
      <PostTitle>{data.contentfulBlogPost.title}</PostTitle>
      <Content>
        {documentToReactComponents(data.contentfulBlogPost.body.json, {
          renderNode: {
            "embedded-asset-block": ({ data }) => {
              if (data.target.fields) {
                return (
                  <Image
                    src={data.target.fields.file["en-US"].url}
                    alt={data.target.fields.title["en-US"]}
                  />
                )
              } else return ""
            },
            "embedded-entry-block": ({ data }) => {
              const post = data.target.fields
              return (
                <PostLink href={"/blog/" + post.slug["en-US"]}>
                  {post.title["en-US"]}
                </PostLink>
              )
            },
          },
        })}
      </Content>
    </Container>
  </Layout>
)

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      preview
      publishedAt(fromNow: true)
      body {
        json
      }
    }
  }
`
